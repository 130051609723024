import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import Expanded from '../common/Expanded';
import Icon from '../common/Icon';

// INPUT
////////
interface StyledInputProps {
  hidden?: boolean;
  error?: boolean;
  highlight?: boolean;
  dense?: boolean;
}
const StyledInput = styled.input<StyledInputProps>`
  display: ${props => (props.hidden ? 'none' : 'block')};
  width: 100%;
  padding: ${props => (props.dense ? '8px 0 8px 12px' : '12px 0 12px 12px')};
  resize: vertical;

  border: 2px solid ${props => (props.error ? props.theme.danger600 : props.theme.lightAccent)};
  border-radius: 4px;
  /* transition: border-color 100ms ease-out; */

  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.textPrimary};
  background-color: ${props => props.theme.sheetBackgroundColor};

  &:hover {
    border: 2px solid ${props => (props.error ? props.theme.danger400 : props.theme.textTertiary)};
  }
  &:focus {
    outline: none;
    border: 2px solid ${props => (props.error ? props.theme.danger400 : props.theme.primary500)};
    box-shadow: ${props => props.theme.shadow300};
  }
  &::placeholder {
    font-weight: normal;
    color: ${props => props.theme.textFaded};
    letter-spacing: 0.025em;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${props => props.theme.textTertiary};
    background-color: ${props => props.theme.hoverFade};
    opacity: 0.5;
  }
`;
const InputButton = styled.button`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 100%;
  margin-left: 2px;

  ${Icon} {
    color: ${props => props.theme.textTertiary};
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${props => props.theme.hoverFade};
    ${Icon} {
      color: ${props => props.theme.textPrimary};
    }
  }
  &:active {
    ${Icon} {
      color: ${props => props.theme.textSecondary};
    }
  }
`;

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  name?: string;
  type?: string;
  dense?: boolean;
  error?: boolean;
  hidden?: boolean;
  forwardedRef?: React.RefObject<HTMLInputElement>;
}

export const Input = styled.input.attrs(
  ({ type = 'text', dense = false, hidden = false, forwardedRef, ...rest }: InputProps) => ({
    type,
    dense,
    ref: forwardedRef,
    hidden,
    ...rest,
  })
)<InputProps>`
  display: ${props => (props.hidden ? 'none' : 'block')};
  width: 100%;
  padding: ${props => (props.dense ? '8px 12px' : '12px')};
  resize: vertical;

  border: 2px solid ${props => (props.error ? props.theme.danger600 : props.theme.lightAccent)};
  border-radius: 4px;
  /* transition: border-color 100ms ease-out; */

  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.textPrimary};
  background-color: ${props => props.theme.sheetBackgroundColor};

  &:hover {
    border: 2px solid ${props => (props.error ? props.theme.danger400 : props.theme.textTertiary)};
  }
  &:focus {
    outline: none;
    border: 2px solid ${props => (props.error ? props.theme.danger400 : props.theme.primary500)};
    box-shadow: ${props => props.theme.shadow300};
  }
  &::placeholder {
    font-weight: normal;
    color: ${props => props.theme.textFaded};
    letter-spacing: 0.025em;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${props => props.theme.textTertiary};
    background-color: ${props => props.theme.hoverFade};
    opacity: 0.5;
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const PasswordInput = ({
  name,
  error = false,
  hidden = false,
  forwardedRef,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <PasswordWrapper>
      <StyledInput
        {...rest}
        name={name}
        id={name}
        error={error}
        hidden={hidden}
        type={showPassword ? 'text' : 'password'}
        ref={forwardedRef}
      />
      <InputButton
        type='button'
        onClick={event => {
          event.preventDefault();
          setShowPassword(!showPassword);
        }}
      >
        <Icon icon={showPassword ? 'visibility' : 'visibility_off'} />
      </InputButton>
    </PasswordWrapper>
  );
};
const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 8px 0;
  width: 100%;

  input {
    padding-left: 40px;
    margin: 0;
  }
  button {
    margin-bottom: 0;
  }

  & > ${Icon} {
    position: absolute;
    top: 11px;
    left: 8px;
    color: ${props => props.theme.textTertiary};
  }
  input:focus + ${Icon} {
    color: ${props => props.theme.linkColor};
  }
`;
interface SearchInputProps extends InputProps {
  handleClear?(): void;
  value?: string;
}
export const SearchInput = ({
  error = false,
  hidden = false,
  handleClear,
  forwardedRef,
  value = '',
  ...rest
}: SearchInputProps) => {
  const [showClearButton, setShowClearButton] = useState(false);

  // const notEmptySearch = forwardedRef && forwardedRef.current && forwardedRef.current.value.trim();
  const notEmptySearch = !!value;
  useEffect(() => {
    if (notEmptySearch) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  }, [notEmptySearch]);

  return (
    <SearchWrapper>
      <StyledInput
        autoComplete='off'
        error={error}
        hidden={hidden}
        value={value}
        {...rest}
        ref={forwardedRef}
      />
      <Icon icon='search' />
      {showClearButton && (
        <InputButton
          type='button'
          onClick={event => {
            event.preventDefault();
            handleClear?.();
            setShowClearButton(false);
          }}
        >
          <Icon icon='close' />
        </InputButton>
      )}
    </SearchWrapper>
  );
};

export const InputError = styled.div`
  font-weight: bold;
  margin-top: 4px;
  color: ${props => props.theme.danger400};
`;

// CHECKBOX
///////////
export const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))<LabeledCheckboxProps>`
  appearance: none;
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  /* background-color: ${props =>
    props.error
      ? props.theme.danger000
      : props.highlight
        ? props.theme.primary000
        : 'transparent'}; */
  border: 2px solid
    ${props =>
      props.error
        ? props.theme.danger500
        : props.highlight
          ? props.theme.primary500
          : props.theme.grey200};
  border-radius: 4px;
  /* transition: border-color 100ms ease-out, box-shadow 100ms ease-out; */

  &:after {
    content: 'check';
    font-family: 'Material Icons Round';
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: -1px;
    opacity: 0;
    color: white;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      outline: none;
      border: 2px solid
        ${props =>
          props.error
            ? props.theme.danger800
            : props.highlight
              ? props.theme.primary800
              : props.theme.grey400};
      box-shadow: ${props => props.theme.shadow200};
    }
    &:hover {
      &:after {
        color: ${props => props.theme.grey200};
        color: ${props =>
          props.error
            ? props.theme.danger200
            : props.highlight
              ? props.theme.primary200
              : props.theme.grey200};
        opacity: 1;
      }
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[type='checkbox']:checked {
    background-color: ${props => props.theme.primary500};
    border: 2px solid ${props => props.theme.primary500};
    &:hover,
    &:focus {
      border: 2px solid ${props => props.theme.primary800};
      color: ${props => props.theme.primary100};
    }

    &:after {
      color: ${props => props.theme.primary000};
      opacity: 1;
    }
  }
`;
const StyledLabeledCheckbox = styled.div`
  display: flex;
  align-items: center;
  &:not(:disabled) {
    &,
    label {
      cursor: pointer;
    }
  }
  label {
    padding: 12px;
    color: ${props => props.theme.textTertiary};
  }
`;
interface LabeledCheckboxProps extends ComponentPropsWithoutRef<'input'> {
  name: string;
  label?: string;
  checked?: boolean;
  error?: boolean;
  highlight?: boolean;
}
export const LabeledCheckbox = ({ name, label = '', ...rest }: LabeledCheckboxProps) => {
  return (
    <StyledLabeledCheckbox>
      <Checkbox name={name} id={name} {...rest} type='checkbox' />
      <label htmlFor={name}>{label}</label>
    </StyledLabeledCheckbox>
  );
};

// RADIO
////////
interface RadioProps {
  active: boolean;
  faded: boolean;
  dense: boolean;
}
const RadioItem = styled.label<RadioProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${({ dense }) => (dense ? '8px 16px' : '16px')};
  margin-bottom: ${({ dense }) => (dense ? '4px' : '12px')};

  font-size: 18px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => (props.faded ? props.theme.textTertiary : props.theme.textPrimary)};
  border: 2px solid ${props => (props.active ? props.theme.primary500 : props.theme.hoverFade)};
  &:hover {
    outline: none;
    border-color: ${props => (props.active ? props.theme.primary500 : props.theme.lightAccent)};
    input {
      border-color: ${props => props.theme.primary500};
    }
  }
  border-radius: 6px;
  background-color: ${props => props.theme.hoverFade};

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.textFaded};
    strong {
      color: ${({ theme }) => theme.textSecondary};
    }
  }

  input {
    flex-shrink: none;
    cursor: pointer;
    appearance: none;
    outline: none;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    border: 2px solid ${props => props.theme.grey300};
    background-color: ${props => props.theme.sheetBackgroundColor};
    border-radius: 100%;
    /* transition: border-color 100ms ease-out, box-shadow 100ms ease-out; */
    &:focus {
      border-color: ${props => props.theme.primary500};
      background-color: ${props => props.theme.grey100};
    }

    &:checked {
      outline: none;
      border-color: ${props => props.theme.primary500};
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
        background-color: ${props => props.theme.primary500};
        border-radius: 100%;
      }
    }
  }
`;
interface RadioListProps extends ComponentPropsWithoutRef<'input'> {
  value: string;
  name: string;
  items: { id: string; value: string; label: string; trailing?: React.ReactNode }[];
  dense?: boolean;
}
export const RadioList = ({
  value,
  name,
  onChange,
  items,
  dense = false,
  ...rest
}: RadioListProps) => (
  <>
    {items.map(item => {
      return (
        <RadioItem
          htmlFor={item.id}
          key={item.id}
          active={item.value === value}
          faded={!!value && item.value !== value}
          dense={dense}
        >
          <input
            checked={item.value === value}
            type='radio'
            name={name}
            id={item.id}
            value={item.value}
            onChange={onChange}
            {...rest}
          />
          <Expanded>{item.label}</Expanded>
          {!!item.trailing && <span>{item.trailing}</span>}
        </RadioItem>
      );
    })}
  </>
);

// TOGGLE
/////////
// Inspired by: https://www.w3schools.com/howto/howto_css_switch.asp
const StyledToggle = styled.label<{ small: boolean }>`
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: ${({ small }) => (small ? '30px' : '38px')};
  height: ${({ small }) => (small ? '18px' : '22px')};

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: ${({ theme }) => theme.primary500};
      &:before {
        background-color: white;
        transform: translateX(${({ small }) => (small ? '12px' : '16px')});
      }

      &:hover {
        background-color: ${({ theme }) => theme.primary600};
        &:before {
          background-color: ${({ theme }) => theme.grey100};
        }
      }
    }
    &:focus + span {
      box-shadow: 0 0 1px ${({ theme }) => theme.primary600};
    }
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: ${({ theme }) => theme.grey200};
    transition: background-color 250ms;
    &:before {
      position: absolute;
      content: '';
      width: ${({ small }) => (small ? '12px' : '16px')};
      height: ${({ small }) => (small ? '12px' : '16px')};
      left: 3px;
      bottom: 3px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.grey600};
      transition:
        transform 250ms ${({ theme }) => theme.easeStandard},
        background-color 250ms;
    }

    &:hover {
      box-shadow: ${props => props.theme.shadow200};
      background-color: ${({ theme }) => theme.grey100};
      &:before {
        background-color: ${({ theme }) => theme.grey500};
      }
    }
  }
`;

export const Toggle = ({
  small = false,
  ...props
}: { small?: boolean } & ComponentPropsWithoutRef<'input'>) => (
  <StyledToggle small={small}>
    <input type='checkbox' {...props} />
    <span />
  </StyledToggle>
);
