import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { WithId } from '@/classes/Doc';
import Person from '@/classes/Person';

import { FlattenedTimestamps, restoreTimestamps } from '@/lib/firebase';

import { pluralize, stringToProfileName } from '@/lib/helpers';
import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { type SearchParams, SearchResponseHit } from 'typesense/lib/Typesense/Documents';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useOrganization from '@/contexts/organization';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import useDebouncedCallback from '@/hooks/useDebouncedCallback';
import useDebouncedState from '@/hooks/useDebouncedState';
import usePageView from '@/hooks/usePageView';
import useTypesense from '@/hooks/useTypesense';

import AnimatedFloatingSheet from '@/components/layout/AnimatedFloatingSheet';
import MobilePageHeader from '@/components/layout/MobilePageHeader';
import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import PersonLinkItem from './PersonLinkItem';
import PersonLinkItemLoader from './PersonLinkItemLoader';
import ScheduleACallNotice from './ScheduleACallNotice';

import PersonForm from '@/components/forms/PersonForm';

import { SearchInput } from '@/components/formElements/FormElements';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import LottieAnimation from '@/components/common/LazyLottieAnimation';
import LinkButton from '@/components/common/LinkButton';
import Loader from '@/components/common/Loader';
import Margin from '@/components/common/Margin';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import NotebirdIcon from '@/components/svg/NotebirdIcon';

// Lazy load illustration
const AddPersonIllustration = lazy(() => import('../../svg/AddPersonIllustration'));

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;
const GetStartedHeader = styled.div`
  font-size: 20px;
  color: ${props => props.theme.textSecondary};
  strong {
    font-size: 28px;
    white-space: nowrap;
    color: ${props => props.theme.textPrimary};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.primary500};
  }
`;
const GetStartedCopy = styled.div`
  line-height: 1.25;
  color: ${props => props.theme.textFaded};
`;
const ResultsCountWrapper = styled.div`
  text-align: center;
  padding: 16px;
  color: ${({ theme }) => theme.avatarText};
`;
// Mobile specific styles
const MobileAddButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.shadow300};
  /* To make it perfectly round for some reason */
  i {
    width: 39px;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;
const arrowWaveRight = keyframes`
    0% { transform: translateX(16px); opacity: 0.5; }
  100% { transform: translateX(0); opacity: 0.2; }
`;
const GetStartedArrowMobile = styled(Icon)`
  position: absolute;
  bottom: 24px;
  right: 96px;
  color: ${({ theme }) => theme.linkColor};
  animation: ${arrowWaveRight} 800ms ${({ theme }) => theme.easeStandard} infinite alternate;
`;
const arrowWaveUp = keyframes`
    0% { transform: translateY(16px); opacity: 0.2; }
  100% { transform: translateY(0); opacity: 0.5; }
`;
const GetStartedArrow = styled(Icon)`
  color: ${({ theme }) => theme.linkColor};
  animation: ${arrowWaveUp} 800ms ${({ theme }) => theme.easeStandard} infinite alternate;
`;

// Defaults
const MAX_SEARCH_RESULTS = 25;
const SCROLL_DEBOUNCE = 150;
const SCROLL_MAX_WAIT = 300;
const DEFAULT_SCROLL_INDEXES = {
  current: [0, 49],
  next: null as null | number[],
};

// Store facet counts for consumption in filter aside
const DEFAULT_PEOPLE_COUNTS = {
  /** Whether is up to date or not */
  isFetched: false,

  /** All people in this location ( archived and not ) */
  total: 0,

  /** All archived people in this location ( archived and not ) */
  archived: 0,

  /** All people in this location ( based on current archived filter  ) */
  filteredTotal: 0,

  /** All followed people ( based on current archived filter ) */
  following: 0,

  /** people counted by groupId ( ie: { groupId: count } ( based on current archived filter ) **/
  groups: {} as Record<string, number>,
};
export const peopleCountsAtom = atom(DEFAULT_PEOPLE_COUNTS);

// Component
export default function PeoplePage() {
  // Register page view
  usePageView({ title: 'People | Notebird' });

  // App state
  const {
    isActive,
    locationId,
    isTrialing,
    isOrganizationAdmin,
    isLocationAdmin,
    groupId,
    groupName,
    contentFilter,
    showArchivedPeople,
    orderPeopleBy,
    orderPeopleDirection,
    followedPeopleIds,
    peopleScrollCacheId,
    peopleScrollCacheIndex,
    peopleSearchCache,
    kidcardMode,
    // typesensePeopleKey,
  } = useAppState();
  const setAppState = useSetAppState();

  const [organization] = useOrganization();
  const [groups] = useGroups();
  const groupIds = useMemo(() => groups?.map(({ id }) => id) || [], [groups]);

  // Establish typesense updates search
  const { getTypesensePeople } = useTypesense();

  // Get facets counts ( to use in <FilterAside> )
  useSyncPeopleCounts();
  const peopleCounts = useAtomValue(peopleCountsAtom);
  const hasAnyPeople = !!peopleCounts.total;

  // Used to show new person form and potentially create new person from empty search
  const [showNewPersonForm, setShowNewPersonForm] = useState(false);
  const [newPersonFromSearch, setNewPersonFromSearch] = useState({
    defaultFirstName: '',
    defaultLastName: '',
  });

  // Determine pulse / join indicators
  const thresholds = useThresholds();

  // People search stuffs
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue, debouncedSearch] = useDebouncedState({
    defaultValue: peopleSearchCache,
    wait: 200,
  });
  const trimmedSearch = debouncedSearch.trim();
  const isSearching = searchValue.trim() && !!trimmedSearch.length;
  // React query search results
  const { data: searchResults, isFetching: isSearchFetching } = useQuery({
    queryKey: ['people-list-search', !!getTypesensePeople, trimmedSearch, locationId],
    enabled: !!getTypesensePeople && !!trimmedSearch && !!locationId,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!getTypesensePeople || !trimmedSearch || !locationId)
        return { total: 0, hits: [], query: '' };

      const searchParams: SearchParams = {
        q: trimmedSearch,
        query_by: [
          // Higher weights
          'profile.name.full',
          'notes',
          'customId',
          'relationships.profile.name.full',
          // Lower weights
          'emails.address',
          'phones.number',
          'addresses.place.mainText',
          'addresses.place.secondaryText',
        ].join(','),
        exclude_fields: [
          'meta',
          'integration',
          'locationId',
          'groups',
          'sortFirstName',
          'sortLastName',
          'phones',
          'emails',
          'addresses',
          'customId',
          'relationships',
        ],
        per_page: MAX_SEARCH_RESULTS,
        highlight_start_tag: '<em>',
        highlight_end_tag: '</em>',
        snippet_threshold: 9,
        // Push archived to bottom
        sort_by: '_eval(isArchived:false):desc',
      };
      const results = await getTypesensePeople(searchParams);
      return {
        total: results.found,
        hits:
          results.hits?.map(hit => {
            const highlightNotes = hit.highlight?.notes?.snippet ?? hit.document.notes;
            return { ...hit, document: { ...hit.document, notes: highlightNotes } };
          }) ?? [],
        query: trimmedSearch,
      };
    },
  });
  // Navigate and confirm search results with keyboard
  const navigate = useNavigate();
  const searchScrollableRef = useRef<HTMLDivElement>(null);
  const selectedSearchIndexRef = useRef(-1);
  const [selectedSearchIndex, setSelectedSearchIndex] = useState(-1);
  const handleSearchNavigation = useCallback(
    (event: KeyboardEvent) => {
      // Clear search and defocus with escape
      if (event.key === 'Escape') {
        event.preventDefault();
        setSearchValue('');
        setAppState({
          peopleScrollCacheId: '',
          peopleScrollCacheIndex: 0,
          peopleSearchCache: '',
        });
        searchRef.current?.blur();
      }

      if (!isSearching) return;

      const hits = searchResults?.hits ?? [];
      const total = hits.length;

      function scrollToSelected(index: number) {
        const selectedElement = searchScrollableRef.current?.querySelector(
          `[data-index="${index}"]`
        );
        selectedElement?.scrollIntoView({ block: 'nearest' });
      }

      if (event.key === 'ArrowDown' || event.key === 'Tab') {
        event.preventDefault();
        if (total > selectedSearchIndexRef.current + 1) {
          selectedSearchIndexRef.current++;
          setSelectedSearchIndex(selectedSearchIndexRef.current);
          scrollToSelected(selectedSearchIndexRef.current);
        }
        return;
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (selectedSearchIndexRef.current > 0) {
          selectedSearchIndexRef.current--;
          setSelectedSearchIndex(selectedSearchIndexRef.current);
          scrollToSelected(selectedSearchIndexRef.current);
        }
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        const selectedTypesensePerson = hits[selectedSearchIndexRef.current];
        if (selectedTypesensePerson) {
          // Go to page if person found
          navigate('/person/' + selectedTypesensePerson.document.id);
          // And preserve search state
          setAppState({
            peopleScrollCacheId: selectedTypesensePerson.document.id,
            peopleScrollCacheIndex: selectedSearchIndexRef.current,
            peopleSearchCache: searchValue,
          });
          return;
        }

        if (!showNewPersonForm) {
          // If not, create new person (if not already creating one)
          // This populates new person form
          const name = stringToProfileName(searchValue);
          setNewPersonFromSearch({
            defaultFirstName: name.first,
            defaultLastName: name.last,
          });
          // Then opens the form
          setShowNewPersonForm(true);
          // Clear search
          setSearchValue('');
          setAppState({
            peopleScrollCacheId: '',
            peopleScrollCacheIndex: 0,
            peopleSearchCache: '',
          });
        }

        return;
      }

      // Otherwise (not arrow, enter, or esc), reset selected on keypress
      selectedSearchIndexRef.current = 0;
      setSelectedSearchIndex(selectedSearchIndexRef.current);
    },
    [
      isSearching,
      searchResults?.hits,
      setSearchValue,
      setAppState,
      showNewPersonForm,
      navigate,
      searchValue,
    ]
  );
  useEffect(() => {
    const searchInput = searchRef.current;
    if (!searchInput) return;

    searchInput?.addEventListener('keydown', handleSearchNavigation);
    return () => searchInput?.removeEventListener('keydown', handleSearchNavigation);
  }, [handleSearchNavigation]);

  // Main people list/query
  const [scrollIndexes, setScrollIndexes] = useState(DEFAULT_SCROLL_INDEXES);
  // Fetch people who are in viewport via typesense
  const fetchParams = useMemo(() => {
    // Sort by first or last name
    const nameOrder = orderPeopleDirection === 'primary' ? 'asc' : 'desc';
    const sortFirstName = [`profile.name.first:${nameOrder}`, `profile.name.last:${nameOrder}`];
    const sortLastName = [`profile.name.last:${nameOrder}`, `profile.name.first:${nameOrder}`];
    const sort_by = orderPeopleBy === 'Last name' ? sortLastName : sortFirstName;

    // FILTERS depend on a number of factors
    // Show archived if that toggle is on
    const archivedFilter = !showArchivedPeople && 'isArchived:=false';
    // Group filter if group is selected
    const groupFilter = groupId && `groups:=[${groupId}]`;
    const ungroupedFilter = contentFilter === 'ungrouped' && `groups:=[ungrouped]`;
    // Following filter if following is selected
    // We're only going to do up to 120 followed people because typesense only allows up to 4000 characters in a query
    // and 150-ish is about the limit (leaving enough room for other filters). Only a few users have reached this limit so far.
    // Otherwise, a fix would include [multi-search](https://typesense.org/docs/27.1/api/federated-multi-search.html#multi-search-parameters)
    const followingFilter =
      contentFilter === 'following' &&
      followedPeopleIds.length &&
      `id:=[${followedPeopleIds.slice(0, 120).join(',')}]`;

    const params: SearchParams = {
      q: '*',
      sort_by,
      filter_by: [archivedFilter, groupFilter || ungroupedFilter || followingFilter]
        .filter(Boolean)
        .join(' && '),
      // We have to exclude fields because 'lastUpdated' field doesn't come in
      // corrected when _included_
      exclude_fields: [
        'meta',
        'integration',
        'locationId',
        'groups',
        'sortFirstName',
        'sortLastName',
        'notes',
        'phones',
        'emails',
        'addresses',
        'birthday',
        'relationshipStatus',
        'anniversary',
        'gender',
        'customId',
        'relationships',
      ],
    };
    return params;
  }, [
    contentFilter,
    followedPeopleIds,
    groupId,
    orderPeopleBy,
    orderPeopleDirection,
    showArchivedPeople,
  ]);

  const { data: currentScrollPeople, isFetched: currentScrollPeopleFetched } = useQuery({
    queryKey: [
      'current-scroll-people',
      locationId,
      fetchParams,
      scrollIndexes.current[0],
      scrollIndexes.current[1],
    ],
    enabled: !!getTypesensePeople && !!locationId,
    refetchInterval: isSearching ? false : 1000 * 3,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const offset = scrollIndexes.current[0];
      const limit = scrollIndexes.current[1] - offset + 1;
      const results = await getTypesensePeople?.({ ...fetchParams, offset, limit });
      return {
        total: results?.found ?? 0,
        hits: results?.hits ?? [],
        offset,
      };
    },
  });
  const { data: nextScrollPeople, isFetched: nextScrollPeopleFetched } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'next-scroll-people',
      locationId,
      fetchParams,
      scrollIndexes.next?.[0],
      scrollIndexes.next?.[1],
    ],
    enabled: !!getTypesensePeople && !!locationId && !!scrollIndexes.next,
    refetchInterval: isSearching ? false : 1000 * 3,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!scrollIndexes.next) return { total: 0, hits: [], offset: 0 };
      const offset = scrollIndexes.next[0];
      const limit = scrollIndexes.next[1] - offset + 1;
      const results = await getTypesensePeople?.({ ...fetchParams, offset, limit });
      return {
        total: results?.found ?? 0,
        hits: results?.hits ?? [],
        offset,
      };
    },
  });
  const isFetched = currentScrollPeopleFetched && (!scrollIndexes.next || nextScrollPeopleFetched);

  const peopleListTotal = currentScrollPeople?.total || 0;
  const peopleListEmpty = !peopleListTotal;
  // Cache the people so they show up in the list in order ( without losing the previously fetched ones )
  const [peopleList, setPeopleList] = useState<WithId<FlattenedTimestamps<Person>>[]>([]);
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  // Set current people
  useEffect(() => {
    if (!currentScrollPeople) return;
    setPeopleList(prevPeeps => {
      const newDocuments = [...prevPeeps];
      currentScrollPeople.hits.forEach((hit, idx) => {
        const globalIndex = currentScrollPeople.offset + idx;
        newDocuments[globalIndex] = hit.document;
      });
      return newDocuments;
    });
  }, [currentScrollPeople]);
  // Set next people
  useEffect(() => {
    if (!nextScrollPeople) return;
    setPeopleList(prevPeeps => {
      const newDocuments = [...prevPeeps];
      nextScrollPeople.hits.forEach((hit, idx) => {
        const globalIndex = nextScrollPeople.offset + idx;
        newDocuments[globalIndex] = hit.document;
      });
      return newDocuments;
    });
  }, [nextScrollPeople]);

  // Virtual scroller
  const isItemLoaded = useCallback(
    // `index === peopleListTotal` for last birdie in scroll row
    (index: number) => index === peopleListTotal || !!peopleList[index],
    [peopleList, peopleListTotal]
  );

  const [loadMoreItems] = useDebouncedCallback(
    (startIndex: number, stopIndex: number) => {
      // We keep the current if it matches beginning or end of next loaded list
      const keepCurrent =
        Math.abs(startIndex - scrollIndexes.current[0]) <= 1 ||
        Math.abs(stopIndex - scrollIndexes.current[1]) <= 1;

      const newIndexes = {
        current:
          keepCurrent || !scrollIndexes.next
            ? scrollIndexes.current
            : [scrollIndexes.next[0], scrollIndexes.next[1]],
        next: [startIndex, stopIndex],
      };
      setScrollIndexes(newIndexes);
      return Promise.resolve();
    },
    SCROLL_DEBOUNCE,
    { maxWait: SCROLL_MAX_WAIT }
  );

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const showEndSpacer = index === currentScrollPeople?.total;
      const showItem = !showEndSpacer;
      const person = restoreTimestamps(peopleList[index]);
      const { pulseStatus, joinStatus } = getPersonStatus({
        person,
        thresholds,
        isLocationAdmin,
        groupIds,
      });
      return (
        <>
          {/* Each person item */}
          {showItem &&
            (person ? (
              // Loaded
              <PersonLinkItem
                person={person}
                style={style}
                key={person.id}
                boldFirstName={['First name', 'Joined'].includes(orderPeopleBy)}
                boldLastName={['Last name', 'Joined'].includes(orderPeopleBy)}
                pulseStatus={pulseStatus}
                joinStatus={joinStatus}
                onClick={() =>
                  setAppState({ peopleScrollCacheId: person.id, peopleScrollCacheIndex: index })
                }
              />
            ) : (
              // Not loaded yet
              <PersonLinkItemLoader style={style} />
            ))}
          {/* Space at end */}
          {showEndSpacer && (
            <div style={style}>
              <Spacer height='16px' />
              <NotebirdIcon dull height='24px' width='100%' />
              <Spacer height='24px' />
            </div>
          )}
        </>
      );
    },
    [
      currentScrollPeople?.total,
      groupIds,
      isLocationAdmin,
      orderPeopleBy,
      peopleList,
      setAppState,
      thresholds,
    ]
  );

  // Clear search and reset scroll every time location, group, filter, or sort changes
  // ( only AFTER initial render tho )
  const listRef: { current: FixedSizeList | null } = useRef<FixedSizeList>(null);
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setSearchValue('');
    listRef?.current?.scrollToItem(0, 'start');
    setScrollIndexes(DEFAULT_SCROLL_INDEXES);
  }, [
    groupId,
    contentFilter,
    orderPeopleBy,
    orderPeopleDirection,
    showArchivedPeople,
    setSearchValue,
    locationId,
  ]);

  // Take action if scrollId or scrollIndex caches are set ( but not search )
  useEffect(() => {
    if (
      !peopleSearchCache &&
      (peopleScrollCacheId || peopleScrollCacheIndex) &&
      peopleList.length
    ) {
      // ( deferred to make sure listRef has been established )
      setTimeout(() => {
        const foundIndex = peopleScrollCacheId
          ? peopleList.findIndex(({ id }) => id === peopleScrollCacheId)
          : -1;
        const scrollToIndex = foundIndex !== -1 ? foundIndex : peopleScrollCacheIndex;
        listRef.current?.scrollToItem(scrollToIndex, 'start');
        setAppState({ peopleScrollCacheId: '', peopleScrollCacheIndex: 0 });
      });
    }
  }, [peopleList, peopleScrollCacheId, peopleScrollCacheIndex, peopleSearchCache, setAppState]);

  // Take action if search cache is set
  useEffect(() => {
    if (
      peopleSearchCache &&
      (peopleScrollCacheId || peopleScrollCacheIndex) &&
      searchResults?.hits.length
    ) {
      const foundIndex = peopleScrollCacheId
        ? searchResults.hits.findIndex(({ document }) => document.id === peopleScrollCacheId)
        : -1;
      const selectedIndex = foundIndex !== -1 ? foundIndex : peopleScrollCacheIndex;
      selectedSearchIndexRef.current = selectedIndex;
      setSelectedSearchIndex(selectedSearchIndexRef.current);
      setAppState({ peopleScrollCacheId: '', peopleScrollCacheIndex: 0, peopleSearchCache: '' });
      searchRef.current?.focus();
    }
  }, [
    peopleScrollCacheId,
    peopleScrollCacheIndex,
    peopleSearchCache,
    searchResults?.hits,
    setAppState,
  ]);

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader>
        {/* Search bar in header, if has 3 or more people total */}
        {peopleCounts.total >= 3 ? (
          <SearchInput
            className='fade-in'
            name='peopleSearch'
            placeholder={kidcardMode ? 'Search all students' : 'Search all people'}
            value={searchValue}
            forwardedRef={searchRef}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(event.target.value);
            }}
            handleClear={() => setSearchValue('')}
            maxLength={150}
          />
        ) : (
          'People'
        )}
      </MobilePageHeader>

      {/* People List */}
      <Sheet position='left'>
        {/* Wait until facets load */}
        <Loader show={!peopleCounts.isFetched} />

        {/* People list (if has people) */}
        {hasAnyPeople && (
          <>
            {/* 'People' header with search bar ( desktop ) */}
            <SheetHeader
              primary
              leadingIcon='people'
              mainTitle={kidcardMode ? 'Students' : 'People'}
              className='hidden-mobile'
            >
              {/* Search bar in header, if has 3 or more people total */}
              {peopleCounts.total >= 3 && (
                <SearchInput
                  // Only autofocus search is has 10 or more people
                  // ( had to prevent this because of mobile/touch devices virtual keyboard)
                  // autoFocus={peopleList.docs.length > 9}
                  className='fade-in'
                  name='peopleSearch'
                  placeholder={kidcardMode ? 'Search all students' : 'Search all people'}
                  value={searchValue}
                  forwardedRef={searchRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(event.target.value);
                  }}
                  handleClear={() => setSearchValue('')}
                  maxLength={150}
                />
              )}
            </SheetHeader>
            <Loader show={!isFetched} />

            {/* Warning if no people in this view/group ( if NOT searching ) */}
            {isFetched && peopleListEmpty && !isSearching && (
              <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
                <NoneFoundHeader>
                  No people in <NoWrap>{groupName || 'this group'}</NoWrap>
                </NoneFoundHeader>
                <NoneFoundCopy>
                  Back to{' '}
                  <LinkButton
                    onClick={() => setAppState({ contentFilter: 'all', groupId: null })}
                    autoFocus
                  >
                    <strong>all people</strong>
                  </LinkButton>
                </NoneFoundCopy>
              </Padding>
            )}

            {/* Filtered people List ( if NOT searching & has some ) */}
            {/* ( also contains ScheduleACallNotice under certain conditions ) */}
            {!peopleListEmpty && !isSearching && (
              <>
                <Expanded>
                  <AutoSizer>
                    {({ height, width }) => (
                      <InfiniteLoader
                        ref={infiniteLoaderRef}
                        // Plus one for little birdie at end
                        itemCount={peopleListTotal + 1}
                        isItemLoaded={isItemLoaded}
                        loadMoreItems={loadMoreItems}
                        minimumBatchSize={100}
                      >
                        {({ onItemsRendered, ref }) => (
                          <FixedSizeList
                            itemCount={peopleListTotal + 1}
                            onItemsRendered={onItemsRendered}
                            ref={el => {
                              // Funny callback ref to handle "scrollToItem" properly
                              // ( because of InfiniteLoader )
                              listRef.current = el;
                              if (typeof ref === 'function') {
                                ref(el);
                              }
                            }}
                            itemSize={65}
                            width={width}
                            height={height}
                          >
                            {rowRenderer}
                          </FixedSizeList>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </Expanded>
                {peopleListTotal < 8 &&
                  !organization?._integration &&
                  isOrganizationAdmin &&
                  organization?.profile.setupMeetingStatus === 'prompt' &&
                  isTrialing && <ScheduleACallNotice />}
              </>
            )}

            {/* Search list */}
            {isSearching && (
              <>
                <Loader show={isSearchFetching} />
                <Scrollable forwardedRef={searchScrollableRef}>
                  {/* Results (when has some) */}
                  {!!searchResults?.hits.length && (
                    <>
                      {searchResults.hits.map((hit, index) => {
                        const person = restoreTimestamps(hit.document);

                        const { pulseStatus, joinStatus } = getPersonStatus({
                          person,
                          thresholds,
                          isLocationAdmin,
                          groupIds,
                        });

                        const { nameMarkup, subtitleMarkup } = getSearchHighlights(hit);

                        return (
                          <PersonLinkItem
                            person={person}
                            key={person.id}
                            nameMarkup={nameMarkup}
                            subtitleMarkup={subtitleMarkup}
                            pulseStatus={pulseStatus}
                            joinStatus={joinStatus}
                            active={selectedSearchIndex === index}
                            onClick={() =>
                              setAppState({
                                peopleScrollCacheId: person.id,
                                peopleScrollCacheIndex: index,
                                peopleSearchCache: debouncedSearch,
                              })
                            }
                            data-index={index}
                          />
                        );
                      })}
                      {/* Results count at end */}
                      <ResultsCountWrapper>
                        <strong>{searchResults.total}</strong>{' '}
                        {pluralize({ root: 'result', count: searchResults.total })} for &apos;
                        <strong>{searchResults.query}</strong>&apos;
                      </ResultsCountWrapper>
                    </>
                  )}
                  {/* No search results notice */}
                  {!searchResults?.hits.length && (
                    <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
                      <NoneFoundHeader>
                        {isSearchFetching ? 'Searching...' : 'No search results found'}
                      </NoneFoundHeader>
                      {!isSearchFetching && (
                        <NoneFoundCopy>
                          {/* Only show create new button if not already creating */}
                          {!showNewPersonForm && (
                            <LinkButton
                              onClick={() => {
                                // This populates new person form
                                const name = stringToProfileName(searchValue);
                                setNewPersonFromSearch({
                                  defaultFirstName: name.first,
                                  defaultLastName: name.last,
                                });
                                // Then opens the form
                                setShowNewPersonForm(true);
                                // Clear search
                                setSearchValue('');
                                setAppState({ peopleSearchCache: '' });
                              }}
                            >
                              Create person &apos;<strong>{debouncedSearch}</strong>&apos;
                            </LinkButton>
                          )}
                        </NoneFoundCopy>
                      )}
                    </Padding>
                  )}
                </Scrollable>
              </>
            )}
          </>
        )}

        {/* Get started onboarding animation + text ( no people ) */}
        {!hasAnyPeople && peopleCounts.isFetched && (
          <>
            <FlexWrapperCentered className='fade-in'>
              <Margin margin='-16% 0 0' />
              <LottieAnimation path='addPersonIllustrationAnimationData' maxHeight='33%' />
              <Spacer height='24px' />
              <GetStartedHeader>
                Get started by <strong>adding a person</strong>
              </GetStartedHeader>
              <Spacer height='16px' />
              <GetStartedCopy>
                You&apos;ll create updates for this person over time
                <br />
                to record conversations and life events
              </GetStartedCopy>
            </FlexWrapperCentered>
            {/* Pointing arrow ( only for mobile ) */}
            <GetStartedArrowMobile icon='arrow_forward' iconSize='48px' className='shown-mobile' />
          </>
        )}

        {/* How we add people on mobile */}
        <MobileAddButton
          onClick={() => {
            // Clear any value that was already prefilled from search results
            setNewPersonFromSearch({ defaultFirstName: '', defaultLastName: '' });
            // And clear search
            setSearchValue('');
            // Then open form
            setShowNewPersonForm(true);
          }}
          disabled={!isActive}
          data-intercom-target='Add person button - mobile'
        >
          <Icon icon='add' iconSize='36px' />
        </MobileAddButton>
      </Sheet>

      {/* Add New Person CTA */}
      <Sheet position='right' className='hidden-mobile'>
        <FlexWrapperCentered>
          {/* Regular/faded illustration (if has people) */}
          {hasAnyPeople && (
            <>
              <Margin margin='-128px 0 0 ' />
              <ErrorSuspendPlaceholder>
                <AddPersonIllustration width='232px' height='232px' className='fade-in' />
              </ErrorSuspendPlaceholder>
              <Spacer height='48px' />
            </>
          )}
          {/* Add person CTA */}
          <PrimaryButton
            disabled={!isActive}
            leadingIcon='person_add'
            onClick={() => {
              // Clear any value that was already prefilled from search results
              setNewPersonFromSearch({ defaultFirstName: '', defaultLastName: '' });
              // And clear search
              setSearchValue('');
              // Then open form
              setShowNewPersonForm(true);
            }}
            data-intercom-target='Add person button'
          >
            Add new {kidcardMode ? 'student' : 'person'}
          </PrimaryButton>
          {!hasAnyPeople && isActive && <GetStartedArrow icon='arrow_upward' iconSize='48px' />}
        </FlexWrapperCentered>
      </Sheet>

      <AnimatedFloatingSheet>
        {!!showNewPersonForm && (
          <PersonForm
            handleCancel={() => setShowNewPersonForm(false)}
            defaultFirstName={capitalize(newPersonFromSearch.defaultFirstName)}
            defaultLastName={capitalize(newPersonFromSearch.defaultLastName)}
          />
        )}
      </AnimatedFloatingSheet>
    </SheetsWrapper>
  );
}

// Helpers

/** Keep people facets in sync */
function useSyncPeopleCounts() {
  const { groupId, contentFilter, showArchivedPeople, locationId, followedPeopleIds } =
    useAppState();

  const { getTypesensePeople } = useTypesense();
  const { data, isFetched } = useQuery({
    queryKey: [
      'people-counts',
      !!getTypesensePeople,
      locationId,
      showArchivedPeople,
      groupId,
      contentFilter,
    ],
    enabled: !!getTypesensePeople && !!locationId,
    refetchInterval: 1000 * 3,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!getTypesensePeople || !locationId) return DEFAULT_PEOPLE_COUNTS;

      // FILTERS depend on a couple factors
      // Show archived if that toggle is on
      const archivedFilter = !showArchivedPeople && 'isArchived:=false';

      const baseParams = {
        q: '*',
        exclude_fields: '*',
        per_page: 0,
      };

      /** All people in this location ( archived and not ) */
      const { found: total } = await getTypesensePeople(baseParams, {});

      /** All archived people in this location */
      const { found: archived } = await getTypesensePeople(
        { ...baseParams, filter_by: 'isArchived:=true' },
        {}
      );

      /** All people in this location counted by group ( based on current archived filter  ) */
      const { found: filteredTotal, facet_counts: [{ counts: groupFacets }] = [] } =
        await getTypesensePeople(
          {
            ...baseParams,
            facet_by: 'groups',
            filter_by: archivedFilter || undefined,
          },
          {}
        );
      const groups = Object.assign(
        {},
        ...groupFacets.map(({ value, count }) => ({ [value]: count }))
      ) as Record<string, number>;

      return {
        total,
        archived,
        filteredTotal,
        groups,
      };
    },
  });

  const setPeopleCounts = useSetAtom(peopleCountsAtom);
  useEffect(() => {
    setPeopleCounts(
      data
        ? {
            ...data,
            /** People count for those who are being followed by the user ( already archive filtered in appState ) */
            following: followedPeopleIds.length,
            isFetched,
          }
        : DEFAULT_PEOPLE_COUNTS
    );
  }, [data, followedPeopleIds.length, isFetched, setPeopleCounts]);
}

/** Determine pulse / join indicators */
function useThresholds() {
  const [organization] = useOrganization();

  return useMemo(() => {
    if (organization) {
      const {
        preferences: { pulseIndicator, joinDateIndicator },
      } = organization;
      const today = DateTime.local().startOf('day');
      return {
        pulseFirst: pulseIndicator.enabled && today.minus({ days: pulseIndicator.first }),
        pulseSecond: pulseIndicator.enabled && today.minus({ days: pulseIndicator.second }),
        joinedFirst: joinDateIndicator.enabled && today.minus({ days: joinDateIndicator.first }),
        joinedSecond: joinDateIndicator.enabled && today.minus({ days: joinDateIndicator.second }),
      };
    }
  }, [organization]);
}

/** Get specific pulse/join status for a person */
function getPersonStatus({
  person,
  thresholds,
  isLocationAdmin,
  groupIds,
}: {
  person: Person;
  thresholds: ReturnType<typeof useThresholds>;
  isLocationAdmin: boolean;
  groupIds: string[];
}) {
  let pulseStatus: 'danger' | 'warn' | 'fresh' | 'none' = 'fresh';
  let joinStatus: 'new' | 'fading' | undefined = undefined;
  if (!thresholds || !person) return { pulseStatus, joinStatus };

  const { pulseFirst, pulseSecond, joinedFirst, joinedSecond } = thresholds;

  // Determine pulse status (if enabled)
  if (pulseFirst && pulseSecond) {
    // Latest timestamp is always latest for admins
    // but need to find most recent of available groups for members
    const lastUpdatedSeconds = isLocationAdmin
      ? person.lastUpdated.latest
      : orderBy(
          toPairs(person.lastUpdated).filter(([groupId]) => groupIds.includes(groupId)),
          ([, timestamp]) => timestamp?.toMillis(),
          'desc'
        )[0]?.[1];
    if (lastUpdatedSeconds) {
      const pulseDateTime = DateTime.fromMillis(lastUpdatedSeconds.toMillis());
      const isWarn = pulseDateTime < pulseFirst;
      const isDanger = pulseDateTime < pulseSecond;
      pulseStatus = isDanger ? 'danger' : isWarn ? 'warn' : 'fresh';
    } else {
      pulseStatus = 'none';
    }
  }

  // Determine join date status (if enabled)
  if (person.joinDate && joinedFirst && joinedSecond) {
    const joinDateTime = DateTime.fromISO(person.joinDate);
    const isNew = joinDateTime >= joinedFirst;
    const isFading = joinDateTime >= joinedSecond;
    joinStatus = isNew ? 'new' : isFading ? 'fading' : undefined;
  }

  return { pulseStatus, joinStatus };
}

/** For formatting highlighting when searching */
function getSearchHighlights(hit: SearchResponseHit<WithId<FlattenedTimestamps<Person>>>) {
  const person = hit.document;
  const { profile, customId, emails, notes, phones, addresses, relationships } = hit.highlight;
  const nameMarkup = profile?.name?.full?.snippet || person.profile.name.full;
  const subtitleMarkup = (() => {
    // Custom ID
    if (customId?.snippet) return customId.snippet;
    // Emails
    const email = emails?.find(email => email?.address?.matched_tokens?.length)?.address?.snippet;
    if (email) return email;
    // Notes
    if (notes?.snippet) return notes.snippet;
    // Phones
    const phone = phones?.find(phone => phone?.number?.matched_tokens?.length)?.number?.snippet;
    if (phone) return phone;
    // Addresses
    const addressMain = addresses?.find(address => address?.place?.mainText?.matched_tokens?.length)
      ?.place?.mainText?.snippet;
    if (addressMain) return addressMain;
    const addressSecondary = addresses?.find(
      address => address?.place?.secondaryText?.matched_tokens?.length
    )?.place?.secondaryText?.snippet;
    if (addressSecondary) return addressSecondary;
    // Relationships
    const relationship = relationships?.find(
      relationship => relationship?.profile?.name?.full?.matched_tokens?.length
    )?.profile?.name?.full?.snippet;
    if (relationship) {
      const relationshipNameToMatch = relationship?.replace('<em>', '').replace('</em>', '');
      const relationshipType = person.relationships?.find(rel =>
        isFuzzyTextMatch(rel.profile.name.full, relationshipNameToMatch)
      )?.type;
      if (relationshipType) return `${relationship} · ${relationshipType}`;
    }
    return '';
  })();
  return { nameMarkup, subtitleMarkup };
}
