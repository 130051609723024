// Libs
import { useField, useFormikContext } from 'formik';
import { Props as SelectProps } from 'react-select';

import Error from './styled/FormError';
import Label from './styled/FormLabel';
// Form elements
import Select from './styled/FormSelect';

// Props
interface Props extends SelectProps {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  disabled?: boolean;
  options: { label: string; value: string }[];
}

// Component
const FormikSelect = ({
  label,
  labelIcon,
  optional,
  name,
  placeholder = '---',
  options,
  disabled,
  theme,
  ...rest
}: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <Label label={label} labelIcon={labelIcon} htmlFor={name} optional={optional}>
      <Select
        inputId={name}
        name={name}
        placeholder={placeholder}
        isDisabled={disabled || isSubmitting}
        value={
          field.value &&
          Array.isArray(options) &&
          options.find(({ value }) => value === field.value)
        }
        onChange={newValue => {
          const selection = newValue as { value: string; label: string } | undefined;
          setFieldValue(name as never, selection ? selection.value : '');
        }}
        onBlur={field.onBlur}
        options={options}
        {...rest}
      />
      {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null}
    </Label>
  );
};
export default FormikSelect;
