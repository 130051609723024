import styled, { css } from 'styled-components';

import useAppState from '@/contexts/appState';

// Styles
const Title = styled.div`
  flex: 1;
`;
const Count = styled.div`
  margin: 0 8px;
  color: ${props => props.theme.textFaded};
  font-size: 13px;
`;
const StyledFilterButton = styled.button<{ active: boolean; faded: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px 12px 24px;

  text-align: left;
  font-size: 16px;
  color: ${props => props.theme.textSecondary};

  &:hover,
  &:focus {
    outline: none;
    background-color: ${props => props.theme.hoverAsideFade};
  }

  /* Collapsed (single-line) when not active */
  ${Title} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ active }) =>
    active &&
    css`
      ${Title} {
        white-space: normal;
      }
      &:not(:disabled) {
        background-color: ${props => props.theme.sheetBackgroundColor};

        font-weight: bold;
        color: ${props => props.theme.linkColor};

        box-shadow: ${props => props.theme.shadow200};

        &:focus ${Title} {
          text-decoration: underline;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 6px;
          height: 100%;

          background-color: ${props => props.theme.linkColor};
        }
      }
    `};

  transition: opacity 200ms ease;
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.5;
    `};

  &:disabled {
    opacity: 0.45;
    cursor: not-allowed;
    filter: grayscale(88%);
    background-color: transparent;
  }
`;

// Props
interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  count?: number | null | boolean;
  children?: React.ReactNode;
}

// Component
export default function FilterButton({ count, children, active = false, ...props }: Props) {
  // Disable fade on tasks page
  const { filterAsidePage } = useAppState();
  const disableFade = filterAsidePage === 'tasks';
  return (
    <StyledFilterButton active={active} faded={!count && !disableFade} {...props}>
      <Title>{children}</Title>
      {!!count && <Count>{count}</Count>}
    </StyledFilterButton>
  );
}
