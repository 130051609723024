import { FormEvent, HTMLProps, ReactNode, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import SheetHeader from '@/components/layout/SheetHeader';
import { SheetScrollable } from '@/components/layout/Sheets';

import PrimaryButton, { DangerButton, SecondaryButton } from '@/components/common/Buttons';
import Loader from '@/components/common/Loader';
import Spacer from '@/components/common/Spacer';

// Styles
const FlexForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

// Props
interface Props extends HTMLProps<HTMLFormElement> {
  formTitle?: React.ReactNode;
  header?: React.ReactNode;
  danger?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  handleSubmit(event?: FormEvent<HTMLFormElement>): void;
  handleCancel(): void;
  hideCancel?: boolean;
  handleBack?(): void;
  handleDelete?(): void;
  submitBtn?: ReactNode | string;
  disableEnterSubmit?: boolean;
}
// Main floating sheet form component
const FormScaffold = ({
  formTitle = '',
  header,
  danger = false,
  isSubmitting = false,
  disabled = false,
  handleSubmit,
  handleCancel,
  hideCancel = false,
  handleBack,
  handleDelete,
  submitBtn = 'Save',
  disableEnterSubmit = false,
  children,
}: Props) => {
  // Submit when pressing command enter
  const formRef = useRef<HTMLFormElement>(null);
  const handleHotKeys = useCallback(
    (event: KeyboardEvent) => {
      const form = formRef.current;
      if (form) {
        // Command enter (submit-if not disabled)
        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
          if (!disabled) handleSubmit();
        }
        // Escape (close)
        if (event.key === 'Escape') {
          event.preventDefault();
          handleCancel();
        }
        // Enter key (disable submit if preference set)
        if (event.key === 'Enter' && disableEnterSubmit) {
          event.preventDefault();
          return false;
        }
      }
    },
    [disabled, handleSubmit, handleCancel, disableEnterSubmit]
  );
  // Cancel when pressing escape
  // const handleCancelEscape = useCallback(
  //   (event: KeyboardEvent) => {
  //     if (event.key === 'Escape') {
  //       event.preventDefault();
  //       handleCancel();
  //     }
  //   },
  //   [handleCancel]
  // );
  // Setup and destroy key command listeners
  useEffect(() => {
    const form = formRef.current;
    if (form) {
      form.addEventListener('keydown', handleHotKeys);
      // form.addEventListener('keydown', handleCancelEscape);
      return () => {
        form.removeEventListener('keydown', handleHotKeys);
        // form.removeEventListener('keydown', handleCancelEscape);
      };
    }
  }, [handleHotKeys]);

  return (
    <FlexForm onSubmit={handleSubmit} noValidate autoComplete='off' ref={formRef}>
      {/* Title and close btn */}
      <SheetHeader
        primary
        mainTitle={formTitle}
        trailing={
          <SecondaryButton
            trailingIcon='close'
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              handleCancel();
            }}
          />
        }
      >
        {header}
      </SheetHeader>

      {/* Loader */}
      <Loader show={isSubmitting} />

      {/* Main form area */}
      <SheetScrollable>{children}</SheetScrollable>

      {/* Footer - Save and Cancel (and maybe back/delete) */}
      <SheetHeader
        // Delete button
        leading={
          <>
            {/* Back Button */}
            {!!handleBack && (
              <SecondaryButton
                leadingIcon='arrow_back'
                onClick={e => {
                  e.preventDefault();
                  handleBack();
                }}
                disabled={isSubmitting}
              >
                Back
              </SecondaryButton>
            )}
            {/* Delete Button */}
            {!!handleDelete && (
              <SecondaryButton
                danger
                onClick={event => {
                  event.preventDefault();
                  handleDelete();
                }}
                disabled={isSubmitting}
              >
                Delete
              </SecondaryButton>
            )}
          </>
        }
        // Cancel + Save
        trailing={
          <>
            {/* Cancel */}
            {!hideCancel && (
              <SecondaryButton
                dull
                onClick={event => {
                  event.preventDefault();
                  handleCancel();
                }}
                disabled={isSubmitting}
              >
                Cancel
              </SecondaryButton>
            )}
            <Spacer width='16px' />

            {/* Save */}
            {danger ? (
              <DangerButton type='submit' disabled={disabled || isSubmitting}>
                {submitBtn}
              </DangerButton>
            ) : (
              <PrimaryButton type='submit' disabled={disabled || isSubmitting}>
                {submitBtn}
              </PrimaryButton>
            )}
          </>
        }
      />
    </FlexForm>
  );
};

export default FormScaffold;
