import {
  Fragment,
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { fbFunctions, restoreTimestamps } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { getFriendlyDate, pluralize } from '@/lib/helpers';
import getErrorMessage from '@/lib/helpers/getErrorMessage';

import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';
import { type SearchParams } from 'typesense/lib/Typesense/Documents';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useUser from '@/contexts/user';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import useDebouncedState from '@/hooks/useDebouncedState';
import useTypesense from '@/hooks/useTypesense';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';

import { SearchInput } from '@/components/formElements/FormElements';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import FlexRow from '@/components/common/FlexRow';
import LinkButton from '@/components/common/LinkButton';
import Loader from '@/components/common/Loader';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import StickyHeader from '@/components/common/StickyHeader';
import UpdateItem from '@/components/common/UpdateItem';

import NotebirdIcon from '@/components/svg/NotebirdIcon';

// Lazy load illustration
const NoRecentActivityIllustration = lazy(() => import('../../svg/NoRecentActivityIllustration'));

// Styles
// TODO: share some of these styles with MilestonesSheet and beyond
const ScrollRef = styled.div`
  min-height: 100%;
`;
const EndOfList = styled.div`
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.textFaded};
`;

// Defaults
/** Default number of updates that show ( and how many to increment by) */
const RESULTS_PER_PAGE = 20;
/** Maximum number of updates to view ( before showing `run report` message ) */
const MAX_UPDATES_LIMIT = 240;

// Store facet counts for consumption in filter aside
const DEFAULT_UPDATE_COUNTS = {
  /** Whether is up to date or not */
  isFetched: false,

  /** All updates in this location ( archived and not ) */
  total: 0,

  /** All archived updates in this location */
  archived: 0,

  /** All restricted visibility updates in this location  */
  restricted: 0,

  /** All updates in this location ( based on current archived and restricted filters  ) */
  filteredTotal: 0,

  /** Updates counted by all people who are being followed by the user
   *  ( Based on current archived and restricted filters ) */
  following: 0,

  /** Updates counted by groupId ( ie: { groupId: count } )
   * ( Based on current archived and restricted filters ) **/
  groups: {} as Record<string, number>,

  /** Updates counted by Update Type ( filtered even further by groups + following ) */
  types: [] as [string, number][],
};
export const updateCountsAtom = atom(DEFAULT_UPDATE_COUNTS);

// Component
export default function UpdatesSheet() {
  // When on mobile we need to know if updates sheet is active ( for scroll-y stuff below )
  const { sheet } = useParams();
  const showMilestones = sheet !== 'milestones';

  // App state
  const {
    locationId,
    groupId,
    groupName,
    hasSingleGroup,
    contentFilter,
    filterUpdatesBy,
    isSingleUser,
    showArchivedPeople,
    showRestrictedUpdates,
    followedPeopleIds,
  } = useAppState();
  const setAppState = useSetAppState();

  // Establish typesense updates search
  const { getTypesenseUpdates } = useTypesense();

  // Get facets counts ( to use in <FilterAside> and <ActivityFilters> )
  useSyncUpdatesCounts();
  const updateCounts = useAtomValue(updateCountsAtom);

  // Updates search stuffs
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue, debouncedSearch] = useDebouncedState({
    defaultValue: '',
    wait: 200,
  });
  const isSearching = !!debouncedSearch.trim().length;

  // React query list + search
  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      'updates-list',
      !!getTypesenseUpdates,
      locationId,
      debouncedSearch,
      showArchivedPeople,
      showRestrictedUpdates,
      groupId,
      contentFilter,
      filterUpdatesBy,
      followedPeopleIds,
    ],
    enabled: !!getTypesenseUpdates && !!locationId,
    refetchInterval: isSearching ? false : 1000 * 3,
    placeholderData: keepPreviousData,
    queryFn: async ({ pageParam: page }) => {
      if (!getTypesenseUpdates) return { total: 0, hits: [], query: '' };

      const trimmedSearch = debouncedSearch.trim();

      const sort_by = trimmedSearch
        ? // If searching, push archived to bottom
          ['_eval(isArchived:false):desc', 'meta.createdAt._seconds:desc']
        : // If not searching, just sort by date
          'meta.createdAt._seconds:desc';

      // FILTERS depend on a number of factors
      // Show archived if that toggle is on
      const archivedFilter = !showArchivedPeople && 'isArchived:=false';
      // Restricted updates if that toggle is on
      const restrictedFilter = !showRestrictedUpdates && 'visibility:=groups';
      // Group filter if group is selected
      const groupFilter = groupId && `groups:=[${groupId}]`;
      // Following filter if following is selected
      // We're only going to do up to 120 followed people because typesense only allows up to 4000 characters in a query
      // and 150-ish is about the limit (leaving enough room for other filters). Only a few users have reached this limit so far.
      // Otherwise, a fix would include [multi-search](https://typesense.org/docs/27.1/api/federated-multi-search.html#multi-search-parameters)
      const followingFilter =
        !groupId &&
        contentFilter === 'following' &&
        followedPeopleIds.length &&
        `person.id:=[${followedPeopleIds.slice(0, 120).join(',')}]`;
      // Type filter if type is selected in dropdown
      const typeFilter = filterUpdatesBy && `type:=${filterUpdatesBy}`;

      const searchParams: SearchParams = {
        q: trimmedSearch,
        query_by: [
          // Higher weights
          'notes',
          'person.profile.name.full',
          'meta.createdBy.profile.name.full',
          // Lower weights
          'type',
          'place.mainText',
          'place.secondaryText',
        ],
        sort_by,
        // Only filter if we're not searching
        filter_by: trimmedSearch
          ? ''
          : [archivedFilter, restrictedFilter, groupFilter, followingFilter, typeFilter]
              .filter(Boolean)
              .join(' && '),
        per_page: RESULTS_PER_PAGE,
        page,
        highlight_start_tag: '<em>',
        highlight_end_tag: '</em>',
        snippet_threshold: 9,
      };

      const results = await getTypesenseUpdates(searchParams);
      const data = {
        total: results.found,
        hits:
          results.hits?.map(hit => {
            const highlightNotes = hit.highlight?.notes?.snippet ?? hit.document.notes;
            return { ...hit, document: { ...hit.document, notes: highlightNotes } };
          }) ?? [],
        query: trimmedSearch,
      };

      // Get and update facets to tally aside items ( but don't need to refresh on every search)
      const maxPages = Math.ceil(data.total / RESULTS_PER_PAGE);

      if (trimmedSearch) return { data, nextPage: page === maxPages ? undefined : page + 1 };

      return { data, nextPage: page === maxPages ? undefined : page + 1 };
    },
    initialPageParam: 1,
    getNextPageParam: ({ nextPage }) => nextPage,
  });
  const flattenedHits = useMemo(
    () =>
      data?.pages.flatMap(
        page => page.data?.hits.map(hit => restoreTimestamps(hit.document)) ?? []
      ) ?? [],
    [data?.pages]
  );

  // Group updates by day
  const dayGroupedUpdates = useMemo(() => {
    const groups = groupBy(flattenedHits, update =>
      update.meta.createdAt
        ? DateTime.fromMillis(update.meta.createdAt.toMillis()).startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    return orderBy(toPairs(groups), ([millis]) => millis, 'desc');
  }, [flattenedHits]);

  // Clear search every time locationgroup, filter, or sort changes
  useEffect(() => {
    setSearchValue('');
  }, [groupId, contentFilter, filterUpdatesBy, setAppState, setSearchValue]);

  // Set scroll to past mobile search on mount AND
  // any time locationId, groupId, contentFilter, filterUpdatesBy, showArchived changes
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (scrollableRef?.current && scrollToRef?.current) {
      scrollableRef.current.scrollTop = scrollToRef.current.offsetTop;
    }
  }, [
    locationId,
    groupId,
    contentFilter,
    filterUpdatesBy,
    showMilestones, // This is also important so switching to this tab on mobile triggers reset
  ]);

  return (
    <>
      {/* Header w/ search ( desktop only ) */}
      <SheetHeader
        primary
        leadingIcon='library_books'
        mainTitle='Updates'
        // Header fades if has no past updates
        active={!!flattenedHits.length}
        className='hidden-mobile'
      >
        {/* Search updates input if 3 or more total ( desktop ) */}
        {updateCounts.total >= 3 && (
          <SearchInput
            name='updatesSearch'
            placeholder='Search all updates'
            value={searchValue}
            forwardedRef={searchRef}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
            handleClear={() => setSearchValue('')}
            className='fade-in'
          />
        )}
      </SheetHeader>

      {/* Loader */}
      <Loader show={!isFetched || isFetchingNextPage} />

      {/* Main scrollable area */}
      <Scrollable forwardedRef={scrollableRef} endBuffer='0'>
        {/* Search updates input if 3 or more total ( mobile ) */}
        {updateCounts.total >= 3 && (
          <Padding padding='4px 16px' className='shown-mobile'>
            <SearchInput
              name='updatesSearch-mobile'
              placeholder='Search all updates'
              value={searchValue}
              forwardedRef={searchRef}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              handleClear={() => setSearchValue('')}
              className='fade-in'
            />
          </Padding>
        )}

        {/* Div wrapper to scroll to onMount ( and other list changes ) */}
        {/* also at a height to, at minimum, obscure mobile search */}
        <ScrollRef ref={scrollToRef}>
          {/* Search results list ( if searching ) */}
          {isSearching && (
            <>
              {/* None found */}
              {!flattenedHits.length && (
                <Padding padding='48px 32px' className='fade-in'>
                  <NoneFoundHeader>
                    No search results found for &apos;<strong>{data?.pages[0]?.data?.query}</strong>
                    &apos;
                  </NoneFoundHeader>
                  <NoneFoundCopy>
                    <LinkButton onClick={() => setSearchValue('')}>
                      <strong>Clear search</strong>
                    </LinkButton>
                  </NoneFoundCopy>
                </Padding>
              )}
              {/* Loop over results ( if has any ) and print */}
              {!!flattenedHits.length && (
                <>
                  <Spacer height='16px' />
                  {flattenedHits.map(typesenseUpdate => (
                    <UpdateItem key={typesenseUpdate.id} update={typesenseUpdate} showDate />
                  ))}
                  <Spacer height='64px' />
                  <NotebirdIcon width='100%' height='24px' dull />
                  <Spacer height='4px' />
                  <EndOfList>
                    <strong>{flattenedHits.length}</strong>{' '}
                    {pluralize({ root: 'result', count: flattenedHits.length })} for &apos;
                    <strong>{data?.pages[0]?.data?.query}</strong>&apos;
                  </EndOfList>
                </>
              )}
            </>
          )}

          {/* Not searching */}
          {isFetched && !isSearching && (
            <>
              {/* Has updates */}
              {!!dayGroupedUpdates.length && (
                <div className='fade-in'>
                  {/* Map over updates */}
                  {dayGroupedUpdates.map(([millis, updates]) => (
                    <Fragment key={millis}>
                      {/* Date header */}
                      <StickyHeader heading={getFriendlyDate(parseInt(millis))} />
                      {/* Each update on date */}
                      {!!updates &&
                        updates.map(update => (
                          <UpdateItem
                            key={update.id}
                            update={update}
                            hideGroups={hasSingleGroup || !!groupId}
                            hideUser={isSingleUser}
                          />
                        ))}
                    </Fragment>
                  ))}
                  {/* End of list */}
                  <Spacer height='48px' />
                  {/* Show more button ( increments by 20, up to 200 total ) */}
                  {flattenedHits.length < MAX_UPDATES_LIMIT && hasNextPage && (
                    <FlexRow justify='center'>
                      <PrimaryButton
                        outlined
                        small
                        onClick={() => fetchNextPage()}
                        disabled={isFetchingNextPage}
                      >
                        <strong>{isFetchingNextPage ? 'Loading' : 'Show More'}</strong>
                      </PrimaryButton>
                    </FlexRow>
                  )}
                  {/* If ever reaches 200, just point to reports page */}
                  {flattenedHits.length >= MAX_UPDATES_LIMIT && (
                    <EndOfList>
                      You&apos;ve reached the {MAX_UPDATES_LIMIT} limit.
                      <br />
                      Please run an <Link to='/reports/updates'>updates report</Link> to see more.
                    </EndOfList>
                  )}
                </div>
              )}

              {/* Empty notice for no activity for this group */}
              {(!!groupId || contentFilter === 'following') && !flattenedHits.length && (
                <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
                  <ErrorSuspendPlaceholder width='100%' height='164px'>
                    <NoRecentActivityIllustration width='100%' height='164px' className='fade-in' />
                  </ErrorSuspendPlaceholder>
                  <Spacer height='16px' />
                  <NoneFoundHeader>
                    No updates{' '}
                    <NoWrap>
                      for{' '}
                      {groupName ||
                        (contentFilter === 'following' && 'followed people') ||
                        'this group'}
                    </NoWrap>
                  </NoneFoundHeader>
                  <NoneFoundCopy>
                    Back to{' '}
                    <LinkButton
                      onClick={() => setAppState({ contentFilter: 'all', groupId: null })}
                    >
                      <strong>all people</strong>
                    </LinkButton>
                  </NoneFoundCopy>
                </Padding>
              )}

              {/* Empty notice for no activity at all */}
              {!groupId && contentFilter === 'all' && !flattenedHits.length && (
                <Padding padding='48px 32px' className='fade-in'>
                  <Suspense fallback={<div style={{ width: '100%', height: '164px' }} />}>
                    <NoRecentActivityIllustration width='100%' height='164px' className='fade-in' />
                  </Suspense>
                  <Spacer height='16px' />
                  <NoneFoundHeader>
                    Nothing to <NoWrap>see here...</NoWrap>
                  </NoneFoundHeader>
                  <NoneFoundCopy>
                    Create an update within a person&apos;s profile and it will show here in this
                    Updates feed.
                  </NoneFoundCopy>
                </Padding>
              )}
            </>
          )}

          {/* Special button to reset update timestamps (available only on staging) */}
          {import.meta.env.MODE !== 'production' && debouncedSearch === 'reset' && (
            <ResetUpdatesButton />
          )}

          {/* Scoll buffer needs to be INSIDE this div for all that scrollTo on mount stuff */}
          <Spacer height='32px' />
        </ScrollRef>
      </Scrollable>
    </>
  );
}

// Button shown on staging site when searching for 'reset'
// to reset update timestamps to look more recent
const ResetUpdatesButton = () => {
  const { organizationId } = useAppState();
  const [isLoading, setIsLoading] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  const [user] = useUser();

  return user?.profile.email.includes('@notebird.app') ||
    user?.profile.email.includes('@notebird.dev') ? (
    <Padding padding='24px 16px' style={{ textAlign: 'center' }}>
      {!resetComplete && (
        <>
          <PrimaryButton
            outlined
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              try {
                await httpsCallable(fbFunctions, 'resetUpdateTimestamps')({ organizationId });
                setResetComplete(true);
              } catch (error) {
                alert('Error resetting timestamps:\n' + getErrorMessage(error));
                console.error(error);
                setIsLoading(false);
              }
            }}
          >
            Reset update timestamps
          </PrimaryButton>
          <Loader show={isLoading} />
        </>
      )}
      {resetComplete && (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <h3>🙌 Updates reset! 🙌</h3>
      )}
    </Padding>
  ) : null;
};

// Helpers
/** Keep update facets in sync */
function useSyncUpdatesCounts() {
  const {
    groupId,
    contentFilter,
    showArchivedPeople,
    locationId,
    followedPeopleIds,
    showRestrictedUpdates,
  } = useAppState();

  const { getTypesenseUpdates } = useTypesense();
  const { data, isFetched } = useQuery({
    queryKey: [
      'update-counts',
      !!getTypesenseUpdates,
      locationId,
      showArchivedPeople,
      showRestrictedUpdates,
      groupId,
      contentFilter,
      followedPeopleIds,
    ],
    enabled: !!getTypesenseUpdates && !!locationId,
    refetchInterval: 1000 * 3,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!getTypesenseUpdates || !locationId) return DEFAULT_UPDATE_COUNTS;

      // FILTERS depend on a number of factors
      // Show archived if that toggle is on
      const archivedFilter = !showArchivedPeople && 'isArchived:=false';
      // Restricted updates if that toggle is on
      const restrictedFilter = !showRestrictedUpdates && 'visibility:=groups';
      // Group filter if group is selected
      const groupFilter = groupId && `groups:=[${groupId}]`;
      // Following filter if following is selected
      // We're only going to do up to 120 followed people because typesense only allows up to 4000 characters in a query
      // and 150-ish is about the limit (leaving enough room for other filters). Only a few users have reached this limit so far.
      // Otherwise, a fix would include [multi-search](https://typesense.org/docs/27.1/api/federated-multi-search.html#multi-search-parameters)
      const followingFilter =
        followedPeopleIds.length && `person.id:=[${followedPeopleIds.slice(0, 120).join(',')}]`;

      const baseParams = {
        q: '*',
        exclude_fields: '*',
        per_page: 0,
      };

      /** All updates in this location ( archived and not ) */
      const { found: total } = await getTypesenseUpdates(baseParams);

      /** All archived updates in this location ( archived and not ) */
      const { found: archived } = await getTypesenseUpdates({
        ...baseParams,
        filter_by: 'isArchived:=true',
      });

      /** All restricted visibility updates in this location  */
      const { found: restricted } = await getTypesenseUpdates({
        ...baseParams,
        filter_by: 'visibility:=restricted',
      });

      /** All updates in this location ( based on current archived and restricted filters  ) */
      /** Updates counted by group */
      const { found: filteredTotal, facet_counts: [{ counts: groupFacets }] = [] } =
        await getTypesenseUpdates({
          ...baseParams,
          facet_by: 'groups',
          filter_by: [archivedFilter, restrictedFilter].filter(Boolean).join(' && '),
        });
      const groups = Object.assign(
        {},
        ...groupFacets.map(({ value, count }) => ({ [value]: count }))
      ) as Record<string, number>;

      /** Updates counted by all people who are being followed by the user */
      const { found: following } = await getTypesenseUpdates({
        ...baseParams,
        filter_by: [archivedFilter, restrictedFilter, followingFilter].filter(Boolean).join(' && '),
      });

      /** Updates counted by Update Type */
      const { found: allTypes, facet_counts: [{ counts: typeFacets }] = [] } =
        await getTypesenseUpdates({
          ...baseParams,
          facet_by: 'type',
          filter_by: [
            archivedFilter,
            restrictedFilter,
            groupFilter,
            !groupId && contentFilter === 'following' && followingFilter,
          ]
            .filter(Boolean)
            .join(' && '),
        });
      const typeCounts = typeFacets.map(({ count, value }) => [value, count]);
      const types = [['', allTypes], ...typeCounts] as [string, number][];

      return {
        total,
        archived,
        filteredTotal,
        following,
        groups,
        types,
        restricted,
      };
    },
  });

  const setUpdateCounts = useSetAtom(updateCountsAtom);
  useEffect(() => {
    setUpdateCounts(
      data
        ? {
            ...data,
            isFetched,
          }
        : DEFAULT_UPDATE_COUNTS
    );
  }, [data, isFetched, setUpdateCounts]);
}
